import React, { useEffect, useState } from 'react';
import { supabase } from '../../utils/supabaseClient';
import Table from '../../components/Table';
import Select from 'react-select';

const Pagos = () => {
  const [pagos, setPagos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState(null);
  const [cuantia, setCuantia] = useState('');
  const [selectedModalidadPago, setSelectedModalidadPago] = useState({ value: 'Tarjeta de crédito', label: 'Tarjeta de crédito' });
  const [fecha, setFecha] = useState(new Date().toISOString().split('T')[0]);

  useEffect(() => {
    const fetchDatos = async () => {
      try {
        // Fetch pagos data
        const { data: pagosData, error: pagosError } = await supabase
          .from('pagos')
          .select('cliente_id, cuantia, modalidad_de_pago, fecha');

        if (pagosError) {
          throw pagosError;
        }

        // Fetch all cliente data
        const { data: clientesData, error: clientesError } = await supabase
          .from('clientes')
          .select('id, nombre, apellidos');

        if (clientesError) {
          throw clientesError;
        }

        // Combine the data
        const combinedData = pagosData.map(pago => {
          const cliente = clientesData.find(c => c.id === pago.cliente_id);
          return {
            ...pago,
            nombre: cliente ? cliente.nombre : '',
            apellidos: cliente ? cliente.apellidos : '',
          };
        });

        setPagos(combinedData);
        setClientes(clientesData);
      } catch (error) {
        console.error('Error fetching data:', error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchDatos();
  }, []);

  const columns = React.useMemo(() => [
    {
      Header: 'Nombre',
      accessor: 'nombre',
    },
    {
      Header: 'Apellidos',
      accessor: 'apellidos',
    },
    {
      Header: 'Cuantía',
      accessor: 'cuantia',
    },
    {
      Header: 'Modalidad de Pago',
      accessor: 'modalidad_de_pago',
    },
    {
      Header: 'Fecha',
      accessor: 'fecha',
    },
  ], []);

  const handleAddPago = async () => {
    if (!selectedCliente || !cuantia || !selectedModalidadPago || !fecha) {
      alert('Por favor, complete todos los campos');
      return;
    }

    const { data: pagoData, error: pagoError } = await supabase
      .from('pagos')
      .insert([
        { cliente_id: selectedCliente.value, cuantia, modalidad_de_pago: selectedModalidadPago.value, fecha }
      ]);

    if (pagoError) {
      console.error('Error adding pago:', pagoError.message);
      return;
    }

    // Check and update cuotas
    const { data: cuotasData, error: cuotasError } = await supabase
      .from('cuotas')
      .select('id, cuantia, fecha_prevista, estado')
      .eq('cliente_id', selectedCliente.value)
      .order('fecha_prevista', { ascending: true });

    if (cuotasError) {
      console.error('Error fetching cuotas:', cuotasError.message);
      return;
    }

    let remainingAmount = parseFloat(cuantia);
    for (const cuota of cuotasData) {
      if (remainingAmount >= cuota.cuantia && cuota.estado !== 'Cuota Pagada') {
        remainingAmount -= cuota.cuantia;
        await supabase
          .from('cuotas')
          .update({ estado: 'Cuota Pagada' })
          .eq('id', cuota.id);
      }
    }

    setPagos([...pagos, { cliente_id: selectedCliente.value, cuantia, modalidad_de_pago: selectedModalidadPago.value, fecha, nombre: selectedCliente.label.split(' ')[0], apellidos: selectedCliente.label.split(' ')[1] }]);
    setShowForm(false);
  };

  const handleShowForm = () => {
    setShowForm(!showForm);
  };

  const handleClienteChange = (selectedOption) => {
    setSelectedCliente(selectedOption);
  };

  const handleModalidadPagoChange = (selectedOption) => {
    setSelectedModalidadPago(selectedOption);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  const clienteOptions = clientes.map(cliente => ({
    value: cliente.id,
    label: `${cliente.nombre} ${cliente.apellidos}`
  }));

  const modalidadPagoOptions = [
    { value: 'Tarjeta de crédito', label: 'Tarjeta de crédito' },
    { value: 'Transferencia', label: 'Transferencia' },
    { value: 'Efectivo', label: 'Efectivo' },
    { value: 'Descuento', label: 'Descuento' }
  ];

  return (
    <div>
      <button 
        onClick={handleShowForm} 
        className="bg-gradient-to-r from-purple-500 to-blue-500 text-white flex items-center p-2 rounded"
      >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-6 w-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M14.25 7.756a4.5 4.5 0 1 0 0 8.488M7.5 10.5h5.25m-5.25 3h5.25M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
        </svg>
        Añadir Pago
      </button>
      {showForm && (
        <form className="mt-4 p-4 border rounded">
          <label className="block mb-2">
            Cliente:
            <Select
              options={clienteOptions}
              onChange={handleClienteChange}
              className="block w-full mt-1"
            />
          </label>
          <label className="block mb-2">
            Cuantía:
            <input 
              type="number" 
              value={cuantia} 
              onChange={(e) => setCuantia(e.target.value)} 
              className="block w-full mt-1 p-2 border rounded"
            />
          </label>
          <label className="block mb-2">
            Modalidad de Pago:
            <Select
              options={modalidadPagoOptions}
              onChange={handleModalidadPagoChange}
              className="block w-full mt-1"
              value={selectedModalidadPago}
            />
          </label>
          <label className="block mb-2">
            Fecha:
            <input 
              type="date" 
              value={fecha} 
              onChange={(e) => setFecha(e.target.value)} 
              className="block w-full mt-1 p-2 border rounded"
            />
          </label>
          <button 
            type="button" 
            onClick={handleAddPago} 
            className="bg-gradient-to-r from-purple-500 to-blue-500 text-white p-2 rounded"
          >
            Añadir
          </button>
        </form>
      )}
      <Table columns={columns} data={pagos} />
    </div>
  );
}

export default Pagos;
