export const solicitudReestructuracion = {
  name: 'Solicitud de Reestructuración.pdf',
  content: [
      { type: 'title', text: 'SOLICITUD DE REESTRUCTURACIÓN DE PRÉSTAMO HIPOTECARIO' },
      { type: 'title', text: 'MEDIANTE EL CÓDIGO DE BUENAS PRÁCTICAS' },
      { type: 'paragraph', text: 'En {{fecha}}' },
      {
          type: 'paragraph',
          text: 'Doña Josefa Salazar Salazar, mayor de edad, con DNI nº 45558709X, colegiada nº 4587 del Ilustre Colegio de Abogados de Córdoba, en nombre y representación de Don {{nombre}} {{apellidos}}, mayor de edad, con DNI nº {{dniTitular1}} en tanto que deudor/es del préstamo hipotecario número {{numero_prestamo_hipotecario}} que tiene contratado con la entidad {{bancoHipoteca}}',
      },
      { type: 'subtitle', text: 'EXPONE' },
      { type: 'paragraph', text: 'I.- Mi representado se halla en serias dificultades para afrontar el pago de la cuota hipotecaria que se señala en el expositivo, debido a las circunstancias acaecidas en los últimos años. Es por ello que esta parte se ve obligada a solicitar las medidas establecidas en el Código de Buenas Prácticas.' },
      {
          type: 'paragraph',
          text: 'II.- Mi mandante cumple todos los requisitos exigidos por el Real Decreto 6/2012 de 9 de marzo, de medidas urgentes de protección de deudores hipotecarios sin recursos, y se adoptan otras medidas estructurales para la mejora del mercado de préstamos hipotecarios y sus ulteriores modificaciones, como es el el Real Decreto-ley 19/2022, de 22 de noviembre, por el que se establece un Código de Buenas Prácticas para aliviar la subida de los tipos de interés en préstamos hipotecarios sobre vivienda habitual  o las resoluciones de 23 de noviembre de 2022 y de 27 de diciembre de 2023.',
      },

      {
        type: 'paragraph',
        text: 'A continuación se detallan las circunstancias que lo acreditan:',
    },

      { type: 'list', items: [
          'Ingresos inferiores a 4,5 veces el IPREM: Como se desprende de las rentas aportadas y la documentación acreditativa sobre los ingresos del presente año mis mandantes tienen unos ingresos de {{ingresosBrutosAnuales}} € brutos anuales.',
          'Alteración significativa de las circunstancias económicas: esta problemática viene dada por la situación que vive la variabilidad del tipo de interés variable, situación que afecta a todos aquellos deudores que tienen el euribor aplicado en su préstamo hipotecario. De esta forma, mi representado ha pasado de pagar {{cuotaHace4Anos}} € hace cuatro años a {{pagoMensualHipoteca}} € que paga actualmente.',
          'Porcentaje de la cuota hipotecaria: la cuota hipotecaria supone más del {{porcentajeCuotaIngresosNetos}}% sobre los ingresos netos de mi mandante.',
          'Incremento del esfuerzo que representa la carga hipotecaria: en relación a la simulación que se acompaña se observa que el incremento del esfuerzo ha aumentado en los últimos 4 años.',
          
      ] },
      {
        type: 'paragraph',
        text: 'III.- {{textoUsuario}}',
    },
      { type: 'subtitle', text: 'SOLICITA' },
      { type: 'paragraph', text: 'En relación a la ampliación del plazo de amortización, esta parte quiere conocer las consecuencias de las medidas señaladas anteriormente, por lo que requiere a la entidad a la que se dirige que emita un cuadro de amortización simulando la cuota resultante tras la carencia reflejada en el expositivo sin ampliar el plazo de amortización y otro cuadro de amortización ampliando el máximo permitido.' },
      { type: 'paragraph', text: 'A la presente solicitud se acompaña:' },
      { type: 'list', items: [
          'Simulación realizada con los datos de mis mandantes y las medidas que se deben establecer',
          'Documentación acreditativa del cumplimiento de los requisitos',
          'DNI de las partes',
          'Autorización que faculta a Doña Josefa Salazar Salazar para ser la representante del deudor',
          'Modelo normalizado declaración responsable del Código de Buenas Prácticas',
      ] },
      { type: 'paragraph', text: 'Datos de contacto Josefa Salazar Salazar:' },
      { type: 'paragraph', text: 'correo electrónico: fina@codigobuenaspracticas.es' },
      { type: 'paragraph', text: 'Teléfono: 653810566' },
      { type: 'paragraph', text: 'Fdo. Josefa Salazar Salazar' },
  ],
};
