import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { supabase } from '../../utils/supabaseClient';
import Table from '../../components/Table';

const Clientes = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      console.log('Fetching data from Supabase...');
      const { data, error } = await supabase
        .from('clientes')
        .select(`
          id, 
          nombre, 
          apellidos, 
          telefono, 
          email, 
          customer_journey (estado)
        `);

      if (error) {
        console.error('Error fetching data:', error);
        setError(error.message);
      } else {
        console.log('Data fetched successfully:', data);
        // Flatten the data structure to make it easier to use with the table component
        const formattedData = data.map(cliente => ({
          ...cliente,
          estado: cliente.customer_journey?.estado
        }));
        setData(formattedData);
      }
    };

    fetchData();
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Nombre',
        accessor: 'nombre',
        Cell: ({ row }) => (
          <Link to={`/clientes/${row.original.id}`} className="text-blue-500 hover:underline">
            {row.original.nombre}
          </Link>
        ),
      },
      {
        Header: 'Apellidos',
        accessor: 'apellidos',
      },
      {
        Header: 'Teléfono',
        accessor: 'telefono',
      },
      {
        Header: 'Correo Electrónico',
        accessor: 'email',
      },
      {
        Header: 'Estado',
        accessor: 'estado',
      },
    ],
    []
  );

  return (
    <div>
      {error && <div>Error: {error}</div>}
      <Table columns={columns} data={data} />
    </div>
  );
}

export default Clientes;
