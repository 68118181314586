import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import Table from '../../components/Table'; // Asegúrate de que la ruta sea correcta para tu proyecto
import { supabase } from '../../utils/supabaseClient';

const Leads = () => {
  const [data, setData] = useState([]);
  const [usuarios, setUsuarios] = useState([]);
  const [editableLeadId, setEditableLeadId] = useState(null);

  useEffect(() => {
    const fetchLeads = async () => {
      // Fetch data from simulacion_resultados
      const { data: resultados, error: errorResultados } = await supabase
        .from('simulacion_resultados')
        .select('id, nombre, apellidos, telefono, email, provincia, usuario_asignado');

      if (errorResultados) {
        console.error('Error fetching simulacion_resultados:', errorResultados);
        return;
      }

      // Fetch data from customer_journey using the IDs from simulacion_resultados
      const ids = resultados.map(resultado => resultado.id);
      const { data: journey, error: errorJourney } = await supabase
        .from('customer_journey')
        .select('user_id, estado')
        .in('user_id', ids);

      if (errorJourney) {
        console.error('Error fetching customer_journey:', errorJourney);
        return;
      }

      // Combine data
      const combinedData = resultados.map(resultado => {
        const journeyData = journey.find(j => j.user_id === resultado.id);
        return {
          ...resultado,
          estado: journeyData ? journeyData.estado : 'N/A',
        };
      });

      setData(combinedData);
    };

    const fetchUsuarios = async () => {
      const { data: usuarios, error } = await supabase
        .from('usuarios')
        .select('user_id, nombre, apellidos');

      if (error) {
        console.error('Error fetching usuarios:', error);
        return;
      }

      setUsuarios(usuarios.map(usuario => ({
        value: usuario.user_id,
        label: `${usuario.nombre} ${usuario.apellidos}`,
      })));
    };

    fetchLeads();
    fetchUsuarios();
  }, []);

  const handleUsuarioChange = async (leadId, userId) => {
    const { error } = await supabase
      .from('simulacion_resultados')
      .update({ usuario_asignado: userId })
      .eq('id', leadId);

    if (error) {
      console.error('Error updating usuario_asignado:', error);
    } else {
      setEditableLeadId(null);
      setData((prevData) =>
        prevData.map((lead) =>
          lead.id === leadId ? { ...lead, usuario_asignado: userId } : lead
        )
      );
    }
  };

  const customStyles = {
    menuPortal: base => ({ ...base, zIndex: 9999 })
  };

  const columns = [
    {
      Header: 'Nombre',
      accessor: 'nombre',
      Cell: ({ row }) => (
        <Link to={`/leads/${row.original.id}`} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
          {row.original.nombre}
        </Link>
      ),
    },
    {
      Header: 'Apellidos',
      accessor: 'apellidos',
    },
    {
      Header: 'Correo',
      accessor: 'email',
    },
    {
      Header: 'Teléfono',
      accessor: 'telefono',
    },
    {
      Header: 'Provincia',
      accessor: 'provincia',
    },
    {
      Header: 'Estado',
      accessor: 'estado',
    },
    {
      Header: 'Usuario Asignado',
      accessor: 'usuario_asignado',
      Cell: ({ row }) => {
        const usuarioAsignado = row.original.usuario_asignado;
        const isEditable = editableLeadId === row.original.id;
        return (
          <div className="flex items-center justify-between">
            {isEditable ? (
              <Select
                options={usuarios}
                onChange={(selectedOption) => handleUsuarioChange(row.original.id, selectedOption.value)}
                placeholder="Asignar Usuario"
                className="w-56"
                styles={customStyles}
                menuPortalTarget={document.body}
              />
            ) : (
              <span>{usuarios.find(user => user.value === usuarioAsignado)?.label || 'No asignado'}</span>
            )}
            <button
              className="ml-2 text-gray-500 hover:text-gray-700"
              onClick={() => setEditableLeadId(isEditable ? null : row.original.id)}
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
              </svg>
            </button>
          </div>
        );
      },
    },
  ];

  return (
    <div className="container mx-auto px-4">
      <div className="flex justify-between mb-4">
        <h1 className="text-2xl font-bold">Leads</h1>
      </div>
      <Table columns={columns} data={data} />
    </div>
  );
};

export default Leads;
