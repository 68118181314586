import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { supabase } from '../../utils/supabaseClient';
import Actividades from '../../components/Actividades';
import Select from 'react-select';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const ClienteDetalle = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [cliente, setCliente] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    nombre: '',
    apellidos: '',
    email: '',
    telefono: '',
    domicilio: '',
    estado: '',
    bancoHipoteca: '',
    ingresosBrutosAnuales: '',
    pagoMensualHipoteca: '',
    cantidadPorPagar: '',
    interesAnual: '',
    cuotaHace4Anos: '',
    ingresosHace4Anos: '',
    ingresosNetosAnuales: '',
    plazoOriginalHipoteca: '',
    cuotaJunio2022: '',
    Carencia: '',
    NuevaCuota: '',
    InteresCarencia: '',
    fechaVencimiento: '',
    numeroTitulares: '',
    miembrosUnidadFamiliar: '',
    numero_prestamo_hipotecario: '' // Actualizado el nombre de la propiedad
  });

  const [expandedSections, setExpandedSections] = useState({
    'Información Personal': false,
    'Estado Cliente': false,
  });

  const [isEditingEstado, setIsEditingEstado] = useState(false);
  const [isEditingField, setIsEditingField] = useState({});

  const estados = [
    "Cliente Primeros Pasos",
    "Cliente Subir Doc",
    "Cliente Firmar Documentación",
    "Cliente Como Va mi Caso",
    "Cliente Caso Resuelto",
    "Cliente solicitud enviada",
    "Recordatorio al banco",
    "Reclamación propuesta",
    "Reclamación BDE",
    "Revisión notaria/acuerdo",
    "Firma notaría/acuerdo",
    "Caso Resuelto"
  ];

  const estadoOptions = estados.map(estado => ({
    value: estado,
    label: estado
  }));

  useEffect(() => {
    const fetchCliente = async () => {
      try {
        const cleanedId = id.trim();
        console.log(`Buscando por cleanedId: '${cleanedId}'`);

        // Consulta en la tabla 'clientes'
        let { data: clienteData, error: clienteError } = await supabase
          .from('clientes')
          .select('*')
          .eq('id', cleanedId)
          .single();

        if (clienteError) {
          throw new Error('Error fetching cliente from clientes');
        }

        // Consulta en la tabla 'customer_journey'
        let { data: customerJourneyData, error: customerJourneyError } = await supabase
          .from('customer_journey')
          .select('estado')
          .eq('user_id', cleanedId)
          .single();

        if (customerJourneyError) {
          console.warn('No corresponding entry in customer_journey for user_id:', cleanedId);
          setFormData({ ...clienteData, estado: 'Desconocido' });
        } else {
          setFormData({ ...clienteData, estado: customerJourneyData.estado });
        }

        calculateResults(clienteData);
        setCliente(clienteData);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchCliente();
  }, [id]);

  const calculateResults = (data) => {
    let newScreen = '';
    const monthlyNetosAnuales = data.ingresosNetosAnuales / 12;
    const monthlyIngresosHace4Anos = data.ingresosHace4Anos / 12;
    const currentEffortRate = data.pagoMensualHipoteca / monthlyNetosAnuales;
    const historicalEffortRate = data.cuotaHace4Anos / monthlyIngresosHace4Anos;
    const adjustedHistoricalEffortRate = historicalEffortRate * 1.5;
    const effortIncreased = adjustedHistoricalEffortRate <= currentEffortRate;

    if ((effortIncreased && currentEffortRate >= 0.5)) {
      newScreen = 'carencia5';
    } else if (currentEffortRate < 1.5 && currentEffortRate >= 0.5) {
      newScreen = 'carencia2';
    } else if (currentEffortRate >= 0.3 && currentEffortRate < 0.45) {
      newScreen = 'carencia1';
    } else {
      newScreen = 'exclusion';
    }

    let years = 0;
    let nuevoInteres = 0;
    if (newScreen === 'carencia5') {
      nuevoInteres = 3.6;
      years = 5;
    } else if (newScreen === 'carencia2') {
      nuevoInteres = data.interesAnual - 0.5;
      years = 2;
    } else if (newScreen === 'carencia1') {
      nuevoInteres = data.interesAnual - 0.5;
      years = 1;
    }

    let nuevaCuota = (data.cantidadPorPagar * (nuevoInteres / 100)) / 12;
    if (newScreen === 'carencia1' && nuevaCuota < data.cuotaJunio2022) {
      nuevaCuota = data.cuotaJunio2022;
    }

    const ahorro = (data.pagoMensualHipoteca - nuevaCuota) * 12 * years;

    setFormData((prevData) => ({
      ...prevData,
      Carencia: newScreen === 'carencia5' ? 'Carencia 5 años' : newScreen === 'carencia2' ? 'Carencia 2 años' : 'Carencia 1 año',
      NuevaCuota: Math.round(nuevaCuota),
      InteresCarencia: nuevoInteres,
    }));

    saveResults({
      ...data,
      Carencia: newScreen === 'carencia5' ? 'Carencia 5 años' : newScreen === 'carencia2' ? 'Carencia 2 años' : 'Carencia 1 año',
      NuevaCuota: Math.round(nuevaCuota),
      InteresCarencia: nuevoInteres,
    });
  };

  const saveResults = async (data) => {
    const { error } = await supabase
      .from('clientes')
      .update({
        Carencia: data.Carencia,
        NuevaCuota: data.NuevaCuota,
        InteresCarencia: data.InteresCarencia,
      })
      .eq('id', id);

    if (error) {
      console.error('Error updating results:', error.message);
    } else {
      console.log('Results updated successfully');
    }
  };

  const toggleSection = (section) => {
    setExpandedSections((prevSections) => ({
      ...prevSections,
      [section]: !prevSections[section],
    }));
  };

  const handleEstadoChange = async (selectedOption) => {
    const newEstado = selectedOption.value;
    setFormData({ ...formData, estado: newEstado });

    const { error } = await supabase
      .from('customer_journey')
      .upsert({ user_id: id, estado: newEstado }, { onConflict: 'user_id' });

    if (error) {
      console.error('Error updating estado:', error.message);
    } else {
      console.log('Estado updated successfully');
    }

    setIsEditingEstado(false);
  };

  const toggleEditEstado = () => {
    setIsEditingEstado(!isEditingEstado);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const saveField = async (field) => {
    const { error } = await supabase
      .from('clientes')
      .update({ [field]: formData[field] })
      .eq('id', id);

    if (error) {
      console.error(`Error updating ${field}:`, error.message);
    } else {
      console.log(`${field} updated successfully`);
    }

    setIsEditingField({ ...isEditingField, [field]: false });
  };

  const toggleEditField = (field) => {
    setIsEditingField({ ...isEditingField, [field]: !isEditingField[field] });
  };

  const handleUpdateResults = () => {
    calculateResults(formData);
  };

  const generatePDF = () => {
    const doc = new jsPDF();
    let y = 10;

    doc.setFontSize(18);
    doc.text(`Simulación Medidas Codigo Buenas Practicas ${formData.nombre} ${formData.apellidos}`, 14, y);
    y += 20; // Espacio adicional después del título

    const addCategory = (title) => {
      doc.setFontSize(16);
      doc.setFont("helvetica", "bold");
      doc.text(`${title}:`, 14, y);
      doc.setFont("helvetica", "normal");
      y += 8;
    };

    const addField = (label, value) => {
      doc.setFontSize(12);
      doc.text(`${label}: ${value}`, 14, y);
      y += 6;
    };

    // Datos Personales
    addCategory('Datos Personales');
    addField('Nombre', formData.nombre);
    addField('Apellidos', formData.apellidos);
    addField('Teléfono', formData.telefono);
    addField('Email', formData.email);
    addField('Domicilio', formData.domicilio);

    y += 8; // Espacio después de la categoría

    // Datos Hipoteca
    addCategory('Datos Hipoteca');
    addField('Banco donde tiene la hipoteca', formData.bancoHipoteca);
    addField('Cuota mensual hipoteca', formData.pagoMensualHipoteca);
    addField('Cantidad que le falta por pagar de la hipoteca', formData.cantidadPorPagar);
    addField('El interes anual', formData.interesAnual);
    addField('Cuota de la hipoteca hace 4 años', formData.cuotaHace4Anos);
    addField('Plazo original de la hipoteca', formData.plazoOriginalHipoteca);
    addField('Cuota en junio 2022', formData.cuotaJunio2022);
    addField('Fecha Vencimiento', formData.fechaVencimiento);
    addField('Numero titulares', formData.numeroTitulares);
    addField('Miembros Unidad Familiar', formData.miembrosUnidadFamiliar);
    addField('Numero prestamo hipotecario', formData.numero_prestamo_hipotecario); // Campo corregido

    y += 8; // Espacio después de la categoría

    // Ingresos
    addCategory('Ingresos');
    addField('Ingresos brutos anuales', formData.ingresosBrutosAnuales);
    addField('Ingresos netos anuales', formData.ingresosNetosAnuales);
    addField('Ingresos netos hace 4 años', formData.ingresosHace4Anos);

    y += 8; // Espacio después de la categoría

    // Resultados Simulador
    addCategory('Resultados Simulador');
    addField('Carencia', formData.Carencia);
    addField('Nueva Cuota', formData.NuevaCuota);
    addField('Interes Carencia', formData.InteresCarencia);

    doc.save('Simulacion_Codigo_Buenas_Practicas.pdf');
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  const excludedFields = [
    'user_id',
    'created_at',
    'id',
  ];

  const friendlyFieldNames = {
    bancoHipoteca: 'Banco donde tiene la hipoteca',
    ingresosBrutosAnuales: 'Ingresos brutos anuales',
    discapacidad: '¿Tiene a alguien con discapacidad?',
    mayor60: '¿Es mayor de 60 años?',
    dificultadesPago: '¿Ha tenido dificultades para pagar la hipoteca?',
    grupoFamiliar: '¿Pertenece a un grupo familiar vulnerable?',
    pagoMensualHipoteca: 'Cuota mensual hipoteca',
    cantidadPorPagar: 'Cantidad que le falta por pagar de la hipoteca',
    anosFaltantes: 'Cuantos años faltan para pagar la hipoteca',
    interesAnual: 'El interes anual',
    cuotaHace4Anos: 'Cuota de la hipoteca hace 4 años',
    ingresosHace4Anos: 'Ingresos netos hace 4 años',
    provincia: 'Provincia',
    ingresosNetosAnuales: 'Ingresos netos anuales',
    plazoOriginalHipoteca: 'Plazo original de la hipoteca',
    cuotaJunio2022: 'Cuota en junio 2022',
    Carencia: 'Carencia',
    NuevaCuota: 'Nueva Cuota',
    InteresCarencia: 'Interes Carencia',
    estadoLead: 'Estado lead',
    prevision_primerpago: 'Previsión Primer Pago', // Nueva columna añadida
    fechaVencimiento: 'Fecha Vencimiento',
    numeroTitulares: 'Numero titulares',
    miembrosUnidadFamiliar: 'Miembros Unidad Familiar',
    numero_prestamo_hipotecario: 'Numero prestamo hipotecario', // Corregido aquí también
    domicilio: 'Domicilio',
  };

  const categories = {
    'Datos Hipoteca': [
      'bancoHipoteca', 'pagoMensualHipoteca', 'cantidadPorPagar', 'anosFaltantes', 'interesAnual',
      'cuotaHace4Anos', 'plazoOriginalHipoteca', 'cuotaJunio2022'
    ],
    'Ingresos': [
      'ingresosBrutosAnuales', 'ingresosNetosAnuales', 'ingresosHace4Anos'
    ],
    'Resultados Simulador': [
      'Carencia', 'NuevaCuota', 'InteresCarencia'
    ],
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      minWidth: '300px',
      width: '100%',
    }),
    menu: (provided) => ({
      ...provided,
      width: '300px',
      zIndex: 9999,
    }),
  };

  return (
    <div className="container mx-auto px-4 pt-2">
      <div className="flex items-center justify-between mb-2">
        <button
          type="button"
          className="flex items-center text-black bg-gray-200 px-4 py-2 rounded-md mr-2"
          onClick={() => navigate('/clientes')}
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 mr-2">
            <path strokeLinecap="round" strokeLinejoin="round" d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3" />
          </svg>
          Volver
        </button>
      </div>

      <div className="grid grid-cols-3 gap-6">
        <div className="col-span-1 bg-gray-100 p-6 rounded-lg shadow-lg relative">
          <button
            onClick={generatePDF}
            className="absolute top-4 right-4 text-blue-500"
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0 1 10.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0 .229 2.523a1.125 1.125 0 0 1-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0 0 21 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 0 0-1.913-.247M6.34 18H5.25A2.25 2.25 0 0 1 3 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 0 1 1.913-.247m10.5 0a48.536 48.536 0 0 0-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5Zm-3 0h.008v.008H15V10.5Z" />
            </svg>
          </button>
          <div className="flex items-center mb-6">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-10 h-10 mr-4">
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 12c2.28 0 4-1.72 4-4S14.28 4 12 4 8 5.72 8 8s1.72 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
            </svg>
            <div>
              <h2 className="text-xl font-semibold">{formData.nombre} {formData.apellidos}</h2>
              <p className="text-gray-700">{formData.email}</p>
              <p className="text-gray-700">{formData.telefono}</p>
            </div>
          </div>
          <div className="flex items-center mb-6">
            <div className="flex-grow">
              <h2 className="text-xl font-semibold">{friendlyFieldNames['estado']} Estado: {formData.estado}</h2>
            </div>
            <button onClick={toggleEditEstado} className="flex items-center ml-2">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
              </svg>
            </button>
          </div>
          {isEditingEstado && (
            <div className="mb-6">
              <Select
                value={{ value: formData.estado, label: formData.estado }}
                onChange={handleEstadoChange}
                options={estadoOptions}
                autoFocus
                openMenuOnFocus
                styles={customStyles}
              />
            </div>
          )}
          <hr className="mb-4" />
          {Object.keys(categories).map((category, index) => (
            <div key={index} className="mb-4">
              <div
                className="flex items-center justify-between cursor-pointer mb-2"
                onClick={() => toggleSection(category)}
              >
                <h3 className="font-bold text-lg">{category}</h3>
                {expandedSections[category] ? (
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
                  </svg>
                ) : (
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                  </svg>
                )}
              </div>
              {expandedSections[category] && (
                <div className="text-gray-700">
                  {categories[category].map((key, idx) => (
                    formData[key] !== undefined && !excludedFields.includes(key) ? (
                      <div key={idx} className="mb-2 flex items-center">
                        {friendlyFieldNames[key] || key.replace(/([A-Z])/g, ' $1')}: <strong>{formData[key]}</strong>
                        {!['Carencia', 'NuevaCuota', 'InteresCarencia'].includes(key) && (
                          <button onClick={() => toggleEditField(key)} className="ml-2">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
                              <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                            </svg>
                          </button>
                        )}
                        {isEditingField[key] && (
                          <div className="ml-2 flex items-center">
                            <input
                              type="text"
                              name={key}
                              value={formData[key]}
                              onChange={handleInputChange}
                              className="border rounded px-2 py-1"
                            />
                            <button onClick={() => saveField(key)} className="ml-2 text-green-500">
                              Guardar
                            </button>
                          </div>
                        )}
                      </div>
                    ) : (
                      console.log(`Field ${key} is undefined or excluded`)
                    )
                  ))}
                  {category === 'Resultados Simulador' && (
                    <button
                      onClick={handleUpdateResults}
                      className="flex items-center text-blue-500 mt-4"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                      </svg>
                      <span className="ml-2">Actualizar Resultados</span>
                    </button>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>

        <div className="col-span-2">
          <Actividades leadId={null} clienteId={id} />
        </div>
      </div>
    </div>
  );
};

export default ClienteDetalle;
