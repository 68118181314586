import React, { useState, useEffect } from 'react';
import { jsPDF } from 'jspdf';
import { solicitudReestructuracion } from '../config/documentConfig';
import { supabase } from '../utils/supabaseClient'; // Asegúrate de que esta ruta es correcta

const GenerarDocumentacion = ({ clienteId }) => {
  const [cliente, setCliente] = useState(null);
  const [dniTitular1, setDniTitular1] = useState(null);
  const [porcentajeCuotaIngresosNetos, setPorcentajeCuotaIngresosNetos] = useState(null);
  const [tipoDocumento, setTipoDocumento] = useState('');
  const [documentosSolicitados, setDocumentosSolicitados] = useState([]);
  const [error, setError] = useState(null);
  const [textoUsuario, setTextoUsuario] = useState(''); // Estado para el texto del usuario

  useEffect(() => {
    const fetchClienteData = async () => {
      // Fetch client data
      const { data: clienteData, error: clienteError } = await supabase
        .from('clientes')
        .select(`
          nombre, 
          apellidos, 
          bancoHipoteca, 
          numero_prestamo_hipotecario,
          ingresosBrutosAnuales,
          cuotaHace4Anos,
          pagoMensualHipoteca,
          ingresosNetosAnuales
        `)
        .eq('id', clienteId)
        .single();

      if (clienteError) {
        console.error('Error fetching client data:', clienteError);
      } else {
        setCliente(clienteData);
        if (clienteData.pagoMensualHipoteca && clienteData.ingresosNetosAnuales) {
          const porcentaje = ((clienteData.pagoMensualHipoteca * 12) / clienteData.ingresosNetosAnuales) * 100;
          setPorcentajeCuotaIngresosNetos(porcentaje.toFixed(2));
        }
      }

      // Fetch DNI of titular1
      const { data: familiarData, error: familiarError } = await supabase
        .from('familiares')
        .select('dni')
        .eq('cliente_id', clienteId)
        .eq('relacion', 'titular1')
        .single();

      if (familiarError) {
        console.error('Error fetching titular1 DNI:', familiarError);
      } else {
        setDniTitular1(familiarData ? familiarData.dni : null);
      }
    };

    const fetchDocumentosSolicitados = async () => {
      const { data: documentosData, error: documentosError } = await supabase
        .from('documentacion_solicitada')
        .select('*')
        .eq('cliente_id', clienteId)
        .order('created_at', { ascending: false });

      if (documentosError) {
        console.error('Error fetching documentos solicitados:', documentosError);
        setError('Hubo un error al cargar los documentos solicitados. Por favor, inténtalo de nuevo.');
      } else {
        setDocumentosSolicitados(documentosData);
      }
    };

    fetchClienteData();
    fetchDocumentosSolicitados();
  }, [clienteId]);

  const generarPDF = () => {
    const doc = new jsPDF();
    let yOffset = 10;

    const today = new Date();
    const dateStr = `A ${today.getDate().toString().padStart(2, '0')} de ${today.toLocaleString('es-ES', { month: 'long' })} de ${today.getFullYear()}`;

    // Create a copy of the content to replace placeholders
    const content = solicitudReestructuracion.content.map(section => {
      if (section.type === 'paragraph' && section.text.includes('{{')) {
        return {
          ...section,
          text: section.text
            .replace('{{nombre}}', cliente?.nombre || '')
            .replace('{{apellidos}}', cliente?.apellidos || '')
            .replace('{{dniTitular1}}', dniTitular1 || '')
            .replace('{{numero_prestamo_hipotecario}}', cliente?.numero_prestamo_hipotecario || '')
            .replace('{{bancoHipoteca}}', cliente?.bancoHipoteca || '')
            .replace('{{ingresosBrutosAnuales}}', cliente?.ingresosBrutosAnuales || '')
            .replace('{{cuotaHace4Anos}}', cliente?.cuotaHace4Anos || '')
            .replace('{{pagoMensualHipoteca}}', cliente?.pagoMensualHipoteca || '')
            .replace('{{fecha}}', dateStr)
            .replace('{{porcentajeCuotaIngresosNetos}}', porcentajeCuotaIngresosNetos || '')
            .replace('{{textoUsuario}}', textoUsuario || '')
        };
      }
      if (section.type === 'list') {
        return {
          ...section,
          items: section.items.map(item => item
            .replace('{{ingresosBrutosAnuales}}', cliente?.ingresosBrutosAnuales || '')
            .replace('{{cuotaHace4Anos}}', cliente?.cuotaHace4Anos || '')
            .replace('{{pagoMensualHipoteca}}', cliente?.pagoMensualHipoteca || '')
            .replace('{{porcentajeCuotaIngresosNetos}}', porcentajeCuotaIngresosNetos || '')
            .replace('{{textoUsuario}}', textoUsuario || '')
          )
        };
      }
      return section;
    });

    // Add client data to PDF if needed
    content.forEach((section) => {
      switch (section.type) {
        case 'title':
          doc.setFontSize(12);
          doc.text(section.text, 10, yOffset);
          yOffset += 6;
          break;
        case 'subtitle':
          doc.setFontSize(12);
          doc.text(section.text, 10, yOffset);
          yOffset += 6;
          break;
        case 'paragraph':
          doc.setFontSize(10);
          const paragraphLines = doc.splitTextToSize(section.text, 180);
          paragraphLines.forEach(line => {
            if (yOffset > 270) { // Check if yOffset is beyond page height
              doc.addPage();
              yOffset = 10;
            }
            doc.text(line, 10, yOffset);
            yOffset += 5;
          });
          break;
        case 'list':
          doc.setFontSize(10);
          section.items.forEach((item, index) => {
            const itemLines = doc.splitTextToSize(`${index + 1}. ${item}`, 180);
            itemLines.forEach(line => {
              if (yOffset > 270) { // Check if yOffset is beyond page height
                doc.addPage();
                yOffset = 10;
              }
              doc.text(line, 10, yOffset);
              yOffset += 5;
            });
          });
          break;
        default:
          break;
      }
      yOffset += 4; // Extra space after each section
    });

    doc.save(solicitudReestructuracion.name);
  };

  const handleSolicitarDocumentacion = async () => {
    if (!tipoDocumento) {
        setError('El tipo de documento no puede estar vacío.');
        return;
    }

    try {
        const documento = {
            cliente_id: clienteId,
            created_at: new Date().toISOString(),
            estado: 'doc solicitada',
            tipo_documento: tipoDocumento
        };

        console.log('Datos a insertar:', documento);

        const { data: insertData, error: insertError } = await supabase
            .from('documentacion_solicitada')
            .insert([documento]);

        if (insertError) {
            console.error('Error al solicitar documentación:', insertError);
            setError('Hubo un error al solicitar la documentación. Por favor, inténtalo de nuevo.');
        } else {
            // Realizar un fetch para obtener los datos insertados
            const { data: fetchData, error: fetchError } = await supabase
                .from('documentacion_solicitada')
                .select('*')
                .eq('cliente_id', clienteId)
                .order('created_at', { ascending: false })
                .limit(1);

            if (fetchError) {
                console.error('Error al obtener la documentación solicitada:', fetchError);
                setError('Hubo un error al obtener la documentación solicitada. Por favor, inténtalo de nuevo.');
            } else if (fetchData && fetchData.length > 0) {
                console.log('Datos obtenidos correctamente:', fetchData);
                setDocumentosSolicitados(prevDocs => [...prevDocs, fetchData[0]]);
                setTipoDocumento(''); // Limpiar el campo de entrada después de guardar
                setError(null); // Limpiar cualquier error previo
            } else {
                console.error('No se devolvieron datos tras el fetch.');
                setError('No se pudo obtener la respuesta esperada. Por favor, inténtalo de nuevo.');
            }
        }
    } catch (ex) {
        console.error('Excepción al solicitar documentación:', ex);
        setError('Ocurrió una excepción al solicitar la documentación. Por favor, inténtalo de nuevo.');
    }
};

  return (
    <div className="p-4">
      <div className="grid grid-cols-2 gap-4">
        <div>
          <h2 className="text-2xl font-bold mb-4">Genera la solicitud de reestructuración</h2>
          <div className="flex items-center mb-4">
            <textarea
              placeholder="Empieza a escribir para poner lo que necesitas explicar en solicitud de reestructuración.El texto que se escribe aquí se añadira dentro de la solicitud de reestructacion como punto III, asi que es importante redactarlo de forma profesional"
              value={textoUsuario}
              onChange={(e) => setTextoUsuario(e.target.value)}
              className="p-2 border rounded mr-4 w-full h-32"
            />
          </div>
          <div className="flex items-center mb-8 pt-2">
            <button
              onClick={generarPDF}
              className="p-2 bg-gradient-to-r from-purple-500 to-blue-500 text-white rounded hover:from-purple-600 hover:to-blue-600"
            >
              Generar PDF
            </button>
          </div>
        </div>
        <div>
          <h2 className="text-2xl font-bold mb-4">Pide documentación extra</h2>
          <div className="flex items-center mb-8 pt-2">
            <input
              type="text"
              placeholder="Tipo de documento"
              value={tipoDocumento}
              onChange={(e) => setTipoDocumento(e.target.value)}
              className="p-2 border rounded mr-4"
            />
            <button
              onClick={handleSolicitarDocumentacion}
              className="p-2 bg-gradient-to-r from-purple-500 to-blue-500 text-white rounded hover:from-purple-600 hover:to-blue-600"
            >
              Pedir documento
            </button>
          </div>
        </div>
      </div>
      {error && <p className="text-red-600 mb-4">{error}</p>}
      {documentosSolicitados.length > 0 && (
        <div>
          <h3 className="mb-2">Documentos solicitados:</h3>
          {documentosSolicitados.map((doc) => (
            <div key={doc.id} className="p-2 border-b mb-2">
              <p><strong>Tipo de Documento:</strong> {doc.tipo_documento}</p>
              <p><strong>Estado:</strong> {doc.estado}</p>
              <p><strong>Fecha de Creación:</strong> {new Date(doc.created_at).toLocaleString()}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default GenerarDocumentacion;
