import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { supabase } from '../../utils/supabaseClient';
import Actividades from '../../components/Actividades';
import Select from 'react-select';

const LeadDetalle = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [lead, setLead] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    nombre: '',
    apellidos: '',
    email: '',
    telefono: '',
    provincia: '',
    bancoHipoteca: '',
    ingresosBrutosAnuales: '',
    pagoMensualHipoteca: '',
    cantidadPorPagar: '',
    anosFaltantes: '',
    interesAnual: '',
    cuotaHace4Anos: '',
    ingresosHace4Anos: '',
    discapacidad: '',
    mayor60: '',
    dificultadesPago: '',
    grupoFamiliar: '',
    ingresosNetosAnuales: '',
    plazoOriginalHipoteca: '',
    cuotaJunio2022: '',
    Carencia: '',
    NuevaCuota: '',
    InteresCarencia: '',
    estadoLead: '',
    prevision_primerpago: '', // Nueva columna añadida
  });

  const [expandedSections, setExpandedSections] = useState({
    'Datos Hipoteca': false,
    'Ingresos': false,
    'Resultados Simulador': false,
    'Informacion Vulnerable': false,
  });

  const [isEditingEstado, setIsEditingEstado] = useState(false);
  const [isEditingPrevision, setIsEditingPrevision] = useState(false);

  const estados = [
    "Lead Nuevo",
    "Intento de contacto",
    "Mal momento",
    "Lead Simulador en curso",
    "Lead Simulador completado",
    "Lead Pricing",
    "Lead No cumple requisitos",
    "Cliente Primeros Pasos",
    "Cliente Subir Doc",
    "Cliente Firmar Documentación",
    "Cliente Como Va mi Caso",
    "Cliente Caso Resuelto",
    "Cliente solicitud enviada",
    "Recordatorio al banco",
    "Reclamación propuesta",
    "Reclamación BDE",
    "Revisión notaria/acuerdo",
    "Firma notaría/acuerdo",
    "Caso Resuelto"
  ];

  const previsionOptions = [
    { value: '99', label: '99' },
    { value: '249', label: '249' },
    { value: '499', label: '499' },
    { value: '999', label: '999' }
  ];

  const estadoOptions = estados.map(estado => ({
    value: estado,
    label: estado
  }));

  useEffect(() => {
    const fetchLead = async () => {
      try {
        const cleanedId = id.trim();
        console.log(`Buscando por cleanedId: '${cleanedId}'`);

        let { data: simulacionData, error: simulacionError } = await supabase
          .from('simulacion_resultados')
          .select('*')
          .eq('id', cleanedId)
          .single();

        if (simulacionError) {
          throw new Error('Error fetching lead from simulacion_resultados');
        }

        let { data: customerJourneyData, error: customerJourneyError } = await supabase
          .from('customer_journey')
          .select('estado, prevision_primerpago')
          .eq('user_id', cleanedId)
          .single();

        if (customerJourneyError) {
          console.warn('No corresponding entry in customer_journey for user_id:', cleanedId);
          setFormData({ ...simulacionData, estadoLead: 'Desconocido', prevision_primerpago: '' });
        } else {
          setFormData({ ...simulacionData, estadoLead: customerJourneyData.estado, prevision_primerpago: customerJourneyData.prevision_primerpago });
        }

        setLead(simulacionData);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchLead();
  }, [id]);

  const toggleSection = (section) => {
    setExpandedSections((prevSections) => ({
      ...prevSections,
      [section]: !prevSections[section],
    }));
  };

  const handleEstadoChange = async (selectedOption) => {
    const newEstado = selectedOption.value;
    setFormData({ ...formData, estadoLead: newEstado });

    const { error } = await supabase
      .from('customer_journey')
      .upsert({ user_id: id, estado: newEstado }, { onConflict: 'user_id' });

    if (error) {
      console.error('Error updating estado:', error.message);
    } else {
      console.log('Estado updated successfully');
    }

    setIsEditingEstado(false);
  };

  const handlePrevisionChange = async (selectedOption) => {
    const newPrevision = selectedOption.value;
    setFormData({ ...formData, prevision_primerpago: newPrevision });

    const { error } = await supabase
      .from('customer_journey')
      .upsert({ user_id: id, prevision_primerpago: newPrevision }, { onConflict: 'user_id' });

    if (error) {
      console.error('Error updating prevision_primerpago:', error.message);
    } else {
      console.log('Prevision Primer Pago updated successfully');
    }

    setIsEditingPrevision(false);
  };

  const toggleEditEstado = () => {
    setIsEditingEstado(!isEditingEstado);
  };

  const toggleEditPrevision = () => {
    setIsEditingPrevision(!isEditingPrevision);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  const excludedFields = [
    'user_id',
    'precioVivienda',
    'firmasteHipoteca',
    'viviendaHabitual',
    'politicaPrivacidad',
    'created_at',
    'id',
    'usuario_asignado',
  ];

  const friendlyFieldNames = {
    bancoHipoteca: 'Banco donde tiene la hipoteca',
    ingresosBrutosAnuales: 'Ingresos brutos anuales',
    discapacidad: '¿Tiene a alguien con discapacidad?',
    mayor60: '¿Es mayor de 60 años?',
    dificultadesPago: '¿Ha tenido dificultades para pagar la hipoteca?',
    grupoFamiliar: '¿Pertenece a un grupo familiar vulnerable?',
    pagoMensualHipoteca: 'Cuota mensual hipoteca',
    cantidadPorPagar: 'Cantidad que le falta por pagar de la hipoteca',
    anosFaltantes: 'Cuantos años faltan para pagar la hipoteca',
    interesAnual: 'El interes anual',
    cuotaHace4Anos: 'Cuota de la hipoteca hace 4 años',
    ingresosHace4Anos: 'Ingresos netos hace 4 años',
    provincia: 'Provincia',
    ingresosNetosAnuales: 'Ingresos netos anuales',
    plazoOriginalHipoteca: 'Plazo original de la hipoteca',
    cuotaJunio2022: 'Cuota en junio 2022',
    Carencia: 'Carencia',
    NuevaCuota: 'Nueva Cuota',
    InteresCarencia: 'Interes Carencia',
    estadoLead: 'Estado lead',
    prevision_primerpago: 'Previsión Primer Pago', // Nueva columna añadida
  };

  const categories = {
    'Datos Hipoteca': [
      'bancoHipoteca', 'pagoMensualHipoteca', 'cantidadPorPagar', 'anosFaltantes', 'interesAnual',
      'cuotaHace4Anos', 'plazoOriginalHipoteca', 'cuotaJunio2022'
    ],
    'Ingresos': [
      'ingresosBrutosAnuales', 'ingresosNetosAnuales', 'ingresosHace4Anos'
    ],
    'Resultados Simulador': [
      'Carencia', 'NuevaCuota', 'InteresCarencia'
    ],
    'Informacion Vulnerable': [
      'discapacidad', 'mayor60', 'dificultadesPago', 'grupoFamiliar'
    ]
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      minWidth: '300px',
      width: '100%',
    }),
    menu: (provided) => ({
      ...provided,
      width: '300px',
      zIndex: 9999,
    }),
  };

  return (
    <div className="container mx-auto px-4 pt-2">
      <div className="flex items-center mb-2">
        <button
          type="button"
          className="flex items-center text-black bg-gray-200 px-4 py-2 rounded-md mr-2"
          onClick={() => navigate('/leads')}
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 mr-2">
            <path strokeLinecap="round" strokeLinejoin="round" d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3" />
          </svg>
          Volver
        </button>
      </div>

      <div className="grid grid-cols-3 gap-6">
        <div className="col-span-1 bg-gray-100 p-6 rounded-lg shadow-lg">
          <div className="flex items-center mb-6">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-10 h-10 mr-4">
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 12c2.28 0 4-1.72 4-4S14.28 4 12 4 8 5.72 8 8s1.72 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
            </svg>
            <div>
              <h2 className="text-xl font-semibold">{formData.nombre} {formData.apellidos}</h2>
              <p className="text-gray-700">{formData.email}</p>
              <p className="text-gray-700">{formData.telefono}</p>
              <p className="text-gray-700">{friendlyFieldNames['provincia']}: {formData.provincia}</p>
            </div>
          </div>
          <div className="flex items-center mb-6">
            <div className="flex-grow">
              <h2 className="text-xl font-semibold">{friendlyFieldNames['estadoLead']}: {formData.estadoLead}</h2>
            </div>
            <button onClick={toggleEditEstado} className="flex items-center ml-2">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
              </svg>
            </button>
          </div>
          {isEditingEstado && (
            <div className="mb-6">
              <Select
                value={{ value: formData.estadoLead, label: formData.estadoLead }}
                onChange={handleEstadoChange}
                options={estadoOptions}
                autoFocus
                openMenuOnFocus
                styles={customStyles}
              />
            </div>
          )}
          <div className="flex items-center mb-6">
            <div className="flex-grow">
              <h2 className="text-xl font-semibold">{friendlyFieldNames['prevision_primerpago']}: {formData.prevision_primerpago}</h2>
            </div>
            <button onClick={toggleEditPrevision} className="flex items-center ml-2">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
              </svg>
            </button>
          </div>
          {isEditingPrevision && (
            <div className="mb-6">
              <Select
                value={{ value: formData.prevision_primerpago, label: formData.prevision_primerpago }}
                onChange={handlePrevisionChange}
                options={previsionOptions}
                autoFocus
                openMenuOnFocus
                styles={customStyles}
              />
            </div>
          )}
          <hr className="mb-4" />
          {Object.keys(categories).map((category, index) => (
            <div key={index} className="mb-4">
              <div
                className="flex items-center justify-between cursor-pointer mb-2"
                onClick={() => toggleSection(category)}
              >
                <h3 className="font-bold text-lg">{category}</h3>
                {expandedSections[category] ? (
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
                  </svg>
                ) : (
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                  </svg>
                )}
              </div>
              {expandedSections[category] && (
                <div className="text-gray-700">
                  {categories[category].map((key, idx) => (
                    formData[key] !== undefined && !excludedFields.includes(key) ? (
                      <div key={idx} className="mb-2">
                        {friendlyFieldNames[key] || key.replace(/([A-Z])/g, ' $1')}: <strong>{formData[key]}</strong>
                      </div>
                    ) : (
                      console.log(`Field ${key} is undefined or excluded`)
                    )
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>

        <div className="col-span-2">
          <Actividades leadId={id} clienteId={null} />
        </div>
      </div>
    </div>
  );
};

export default LeadDetalle;
